.img-avatar {
    width: 32px;
    height: 32px;
    border: 1px solid white;
    border-radius: 50%;
    background-color: white;
}

.ReactCollapse--collapse {
    color: white;
    border-top: 3px solid white;
    transition: height 500ms;
}
.ReactCollapse--content{
    height: 75px;
    @media (max-width: 576px) {
        height: 125px;
    }

}

.btn-collape {
    background-color: transparent !important;
    border: none !important;
    color: white !important;
}

.btn-collape:focus {
    outline: none !important;
}

.btn{
    border: 1px solid transparent !important;
    border-radius: 0.75rem !important;
    padding: 0.4rem 2rem !important;
    font-size: .75rem !important;
    line-height: 1.125 !important;
    font-weight: bold !important;
    font-family: 'Catamaran', sans-serif !important;
}

.btn-white {
    color: #E9206A !important;
    background-color: white !important;
    border-color: white !important;
}

.btn-white:hover {
    color: #E9206A !important;
    background-color: white !important;
    border-color: white !important;
}
